import { useEffect } from "react"

export default function Milan({ setContent }) {

    const content = {
        imageURL: './imgs/scaled_and_cropped/milan.webp',
        title: 'Милан',
        text: "Фризер сам са 15 година радног искуства. Учио сам у Фризерском салону „Ореол”, а затим радио у салонима на Новом Београду и Бежанијској коси. Овде сам од 2019. године. Специјализован сам за извођење шишања (класична и модерна (фејд, и сл.)), стилизовање браде и прање косе са масажом главе. Увек сам вољан да дам клијентима корисне савете у вези са козметиком, негом косе, стајлингом, итд.",
        phone: '+381656184044'
    }

    useEffect(() => {
        setContent(content)
    }, [])

    return null
}