import { useGLTF } from "@react-three/drei"
import { useThree, useFrame } from "@react-three/fiber"
import { useEffect, useState } from "react"
// import { useControls } from "leva"
import HairdressersEventHandler from "./HairdressersEventHandler"

export default function Hairdressers({ orbitControls }) {

    const { scene } = useThree()
    const [hairdressersLoaded, setHairdressersLoaded] = useState(false)
    
    const bobaGltf = useGLTF('./models/boba.glb')
    const milanGltf = useGLTF('./models/milan.glb')
    const raleGltf = useGLTF('./models/rale.glb')
    const pricelistGltf = useGLTF('./models/pricelist.glb')

    const instagramIconGltf = useGLTF('./models/instagram.glb')
    const instagramIconGltf2 = useGLTF('./models/instagram2.glb')

    // Clone Instagram icons for different models
    const instagramBoba = instagramIconGltf.scene
    const instagramRale = instagramIconGltf2.scene

    // Positioning the model with Leva
    // const { x, y, z } = useControls('Model position', {
    //     x: { value: 0, min: -10, max: 10 },
    //     y: { value: 0, min: -10, max: 10 },
    //     z: { value: 0, min: -10, max: 10 }
    // })

    useEffect(() => {
        const boba = bobaGltf.scene
        const milan = milanGltf.scene
        const rale = raleGltf.scene
        const pricelist = pricelistGltf.scene

        if (boba && milan && rale && pricelist && instagramBoba && instagramRale) {
            boba.position.set(0, (Math.random() - 0.5) * 2, -1.68)
            boba.rotation.y = 0.59
            scene.add(boba)

            // Instagram icon Boba
            instagramBoba.rotation.copy(boba.rotation)
            instagramBoba.position.set(2, 0, -3)
            const iconScale = .13
            instagramBoba.scale.set(iconScale, iconScale, iconScale)
            scene.add(instagramBoba)
            // Instagram icon Boba

            milan.position.set(0, (Math.random() - 0.5) * 2, 2.24)
            milan.rotation.y = 0.73
            scene.add(milan)

            rale.position.set(5.89, (Math.random() - 0.5) * 2, -1.30)
            rale.rotation.y = 6
            scene.add(rale)

            // Instagram icon Rale
            instagramRale.rotation.copy(rale.rotation)
            instagramRale.position.set(8.3, 0, -0.4)
            instagramRale.scale.set(iconScale, iconScale, iconScale)
            scene.add(instagramRale)
            // Instagram icon Rale

            pricelist.position.set(-3.4, 0, 5)
            scene.add(pricelist)

            setHairdressersLoaded(true)
        }
    }, [])

    useFrame(state => {
        // if (instagramRale) {
        //     instagramRale.position.set(x, y, z)
        // }

        const elapsedTime = state.clock.getElapsedTime()

        if (bobaGltf.scene && milanGltf.scene && raleGltf.scene && pricelistGltf.scene && instagramBoba && instagramRale) {
            bobaGltf.scene.position.y = Math.sin(elapsedTime * 2 - 2) * 0.3
            milanGltf.scene.position.y = Math.sin(elapsedTime * 2 - 4) * 0.3
            raleGltf.scene.position.y = Math.sin(elapsedTime * 2 - 6) * 0.3
            pricelistGltf.scene.position.y = 1.4 + Math.sin(elapsedTime * 2 - 7) * 0.3

            instagramBoba.position.y = 1 + Math.sin(elapsedTime * 2 - 1) * 0.7
            instagramRale.position.y = 1 + Math.sin(elapsedTime * 2 - 3) * 0.7
        }
    })

    return (
        <>
            {hairdressersLoaded && <HairdressersEventHandler orbitControls={orbitControls} hairdressers={{ 
                boba: bobaGltf.scene, 
                milan: milanGltf.scene, 
                rale: raleGltf.scene,
                pricelist: pricelistGltf.scene,
                instagramBoba,
                instagramRale
            }} />}
        </>
    )
}

useGLTF.preload('./models/boba.glb')
useGLTF.preload('./models/milan.glb')
useGLTF.preload('./models/rale.glb')
useGLTF.preload('./models/pricelist.glb')
useGLTF.preload('./models/instagram.glb')
