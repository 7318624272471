import { useEffect } from "react"

export default function Boba({ setContent }) {

    const content = {
        imageURL: './imgs/boba.webp',
        title: 'Боба',
        text: "Власник сам Фризерског салона „Ореол” од децембра 1999. године. Салон се налази у Јакову, у улици Проте Стаматовића 30. За 25 година рада израстао је у салон са традицијом, аутентичном и позитивном атмосфером и израдом првокласних фризура. Постоји константно усавршавање и увек радим са жељом да постигнем максималан резултат, који ће бити на задовољство и моје и мојих клијената. Са својих 30 година радног искуства могу да понудим најквалитетније шишање, да ли је то класична, модерна, пословна или тренд фризура са савршеним прелазима и висококвалитетним обликом. Свим својим клијентима сам увек на располагању да дам савет у вези са избором козметике и што лакшег стилизовања косе. Циљ сваког шишања је обострано задовољство и одлазак клијента са осмехом на лицу.",
        phone: '+38163264797'
    }

    useEffect(() => {
        setContent(content)
    }, [])

    return null
}