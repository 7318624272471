import * as THREE from 'three'
import { useThree, useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import Milan from './Hairdressers_content/Milan'
import Boba from './Hairdressers_content/Boba'
import Rale from './Hairdressers_content/Rale'
import { useEffect, useState } from 'react'
import Hairdresser from './Hairdresser'
import Pricelist from './Pricelist'

export default function HairdressersEventHandler({ hairdressers, orbitControls }) {

    const { boba, milan, rale, pricelist, instagramBoba, instagramRale } = hairdressers
    const { camera } = useThree()
    const hairdressersArray = [boba, milan, rale, pricelist, instagramBoba, instagramRale]
    const [ loadedHairdresser, setLoadedHairdresser ] = useState('')
    const [ pricelistLoaded, setPricelistLoaded ] = useState(false)
    const [ hairdresserContent, setHairdresserContent ] = useState({})
    const [ popupClosed, setPopupClosed ] = useState(false)
    
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onMouseMove(event) {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    window.addEventListener('mousemove', onMouseMove)
    const root = document.getElementById('root')

    let intersectedObject = null

    /**
     * Hairdressers animation
     */
    
    // Disable on mobile devices
    const isTouchDevice = window.matchMedia('(any-pointer: coarse)').matches
    if (!isTouchDevice) {
        useFrame(() => {
            raycaster.setFromCamera(mouse, camera)
            const intersects = raycaster.intersectObjects(hairdressersArray)
    
            if (intersects.length > 0) {
                root.style.cursor = 'pointer'
    
                if (intersects[0].object.parent.scale.z > 1.1) { return }

                if (intersects[0].object.name === 'pricelist') {
                    intersects[0].object.parent.scale.z += 0.009
                    intersects[0].object.parent.scale.y += 0.009
                }
                else if (
                    intersects[0].object.name === 'boba_insta' || // check if the user is hovering the Instagram icons
                    intersects[0].object.name === 'rale_insta' || // check if the user is hovering the Instagram icons
                    intersects[0].object.name === 'boba_insta_plane' || // check if the user is hovering the Instagram icons
                    intersects[0].object.name === 'rale_insta_plane' // check if the user is hovering the Instagram icons
                ) {
                    return
                }
                else {
                    intersects[0].object.parent.scale.z += 0.009
                    intersects[0].object.parent.scale.x += 0.009
                }

                intersectedObject = intersects[0]
            }
            else if (intersectedObject) {
                root.style.cursor = 'unset'
                hairdressersArray.forEach(hairdresser => {

                    if (
                        hairdresser.children[0].name === 'boba_insta' || 
                        hairdresser.children[0].name === 'rale_insta' || 
                        hairdresser.children[0].name === 'boba_insta_plane' || 
                        hairdresser.children[0].name === 'rale_insta_plane') { // check if the object is an Instagram icon
                        return
                    }
                    hairdresser.children[0].scale.set(1, 1, 1)
                })
            }
        })
    }

    /**
     * Animate the camera
     */

    let animationDuration = 1 //seconds

    const animateCamera = (
        positionX, positionY, positionZ, // Camera position
        targetX, targetY, targetZ        // Orbit controls target
    ) => {
        gsap.to(camera.position, {x: positionX, y: positionY, z: positionZ, duration: animationDuration, ease: "sine.inOut"})
        gsap.to(orbitControls.current.target, {x: targetX, y: targetY, z: targetZ, duration: animationDuration, ease: "sine.inOut", onComplete: () => {
            if (!popupClosed) {
                orbitControls.current.enabled = false
            }
        }})
    }

    /**
     * Load the content and animate the camera after clicking on hairdressers
     */

    useEffect(() => {
        const instagramEventHandler = (instaUsername) => {
            const appUrl = `instagram://user?username=${instaUsername}`
            const webUrl = `https://www.instagram.com/${instaUsername}/`

            if (isTouchDevice) {
                window.location.href = appUrl
            }
            else {
                window.location.href = webUrl
            }
        }

        window.addEventListener('click', () => {
            raycaster.setFromCamera(mouse, camera)
            const intersects = raycaster.intersectObjects(hairdressersArray)
            
            if (intersects[0]) {
                if (intersects[0].object.name === 'milan_1') {
                    animateCamera(2.11, 1.60, 4.22, -0.07, 1.14, 2.15)
                    setLoadedHairdresser('milan')
                }
                else if (intersects[0].object.name === 'boba_1') {
                    animateCamera(2.03, 1.80, 0.49, 0.85, 1.5, -0.82)
                    setLoadedHairdresser('boba')
                }
                else if (intersects[0].object.name === 'rale_1') {
                    animateCamera(4.69, 1.32, 1.70, 5.77, 0.71, -1.74)
                    setLoadedHairdresser('rale')
                }
                else if (intersects[0].object.name === 'pricelist') {
                    animateCamera(-0.27, 3.35, 4.89, -2.90, 3.17, 4.92)
                    setPricelistLoaded(true)
                }
                else if (intersects[0].object.name === 'boba_insta' || intersects[0].object.name === 'boba_insta_plane') {
                    instagramEventHandler('f.s.oreol')
                }
                else if (intersects[0].object.name === 'rale_insta' || intersects[0].object.name === 'rale_insta_plane') {
                    instagramEventHandler('rajko_cutz')
                }
            }
        })
    }, [])

    return (
        <>
            {loadedHairdresser === 'milan' && <Milan setContent={setHairdresserContent} />}
            {loadedHairdresser === 'boba' && <Boba setContent={setHairdresserContent} />}
            {loadedHairdresser === 'rale' && <Rale setContent={setHairdresserContent} />}
            {pricelistLoaded && <Pricelist
                animateCamera={animateCamera}
                setPricelistLoaded={setPricelistLoaded}
                animationDuration={animationDuration}
                orbitControls={orbitControls}
                setPopupClosed={setPopupClosed}
            />}
            {loadedHairdresser && 
            <Hairdresser 
                content={hairdresserContent} 
                setLoadedHairdresser={setLoadedHairdresser} 
                animateCamera={animateCamera}
                animationDuration={animationDuration}
                orbitControls={orbitControls}
                setPopupClosed={setPopupClosed}
            />}
        </>
    )
}