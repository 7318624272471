// import { useControls } from "leva"

export default function Light() {

    // const { x, y, z } = useControls('Directional Light Position', {
    //     x: { value: 4, min: -10, max: 20 },
    //     y: { value: 10, min: -10, max: 20 },
    //     z: { value: 17, min: -10, max: 20 }
    // })

    return (
        <>
            <directionalLight
                position={[4, 10, 17]}
                intensity={2}
            />
            <ambientLight intensity={0.4}/>
        </>
    )
}
