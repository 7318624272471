import { Environment } from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/Addons.js"
import { gsap } from "gsap/gsap-core"
import { useThree } from "@react-three/fiber"
import * as THREE from 'three'
import { useEffect } from "react"
import Hairdressers from "./Hairdressers/Hairdressers"
import { DRACOLoader } from "three/examples/jsm/Addons.js"

/**
 * Loading screen
 */
const loadingBarElement = document.querySelector('.loading-bar')
const loadingPercentElement = document.querySelector('.loading-percent')
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little
        window.setTimeout(() =>
        {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
            // Update loadingBarElement and fade out footer
            loadingBarElement.classList.add('ended')
            loadingPercentElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        }, 500)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal
        loadingPercentElement.innerText = `${(progressRatio * 100).toFixed(0)}%`
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(vec3(0), uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)

export default function Saloon({ orbitControls }) {

    const { scene } = useThree()

    useEffect(() => {

        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('./draco/')

        const gltfLoader = new GLTFLoader(loadingManager)
        gltfLoader.setDRACOLoader(dracoLoader)

        gltfLoader.load(
            './models/saloon.glb',
            saloon => {
                if (saloon) {
                    saloon.scene.rotation.y = -Math.PI / 2
                    saloon.scene.position.y = -3
                    scene.add(saloon.scene)
                }
            }
        )
        scene.add(overlay)
    }, [])

    return (
        <>
            <Hairdressers orbitControls={orbitControls} />
            <Environment preset="city" />
        </>
    )
}