import { useEffect } from "react"

export default function Rale({ setContent }) {

    const content = {
        imageURL: './imgs/scaled_and_cropped/rale.webp',
        title: 'Рајко',
        text: "Имам 20 година. Учио сам у салону „Ореол” 3 године и одмах после завршене школе почео сам да радим у салону. Имам 2 године радног искуства, али без обзира на године радим све модерне и тренди фризуре (све врсте фејда: high fade, mid fade, low fade, taper fade, buzz cut, french crop, mullet…) и оне су омиљени изазов за мене. Мој клијент постаје и мој пријатељ јер код мене влада опуштена и весела атмосфера. Објаснићу вам и научити вас како да сами стилизујете своју фризуру код куће.",
        phone: '+381695126265'
    }

    useEffect(() => {
        setContent(content)
    }, [])

    return null
}