import { Html } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import useCameraCoordinates from '../stores/useCameraCoordinates'
import gsap from 'gsap'

export default function Pricelist({ 
    setPricelistLoaded, 
    animateCamera, 
    animationDuration, 
    orbitControls, 
    setPopupClosed
 }) {

    const content = [
        {
            service: 'Мастер шишање',
            price: '1.500'
        },
        {
            service: 'Шишање',
            price: '1.200'
        },
        {
            service: 'Класично шишање',
            price: '1.000'
        },
        {
            service: 'Фазон',
            price: '800'
        },
        {
            service: 'Обликовање браде',
            price: '600'
        },
        {
            service: 'Тримовање браде',
            price: '300'
        },
        {
            service: 'Пензионери',
            price: '800'
        },
        {
            service: 'Бријање',
            price: '500'
        },
        {
            service: 'Прање косе',
            price: '200'
        },
        {
            service: 'Прање косе (са масажом главе)',
            price: '500'
        },
        {
            service: 'Фенирање',
            price: '500'
        }
    ]

    const cameraCoordinates = useCameraCoordinates(state => state.cameraCoordinates)

    /**
     * Close the hairdresser pop-up
     */
    const closeButtonRef = useRef()
    const priceList = useRef()
    const [openClass, setOpenClass] = useState('')

    useEffect(() => {
        // Close button handler
        setTimeout(() => {
            if (closeButtonRef) {
                closeButtonRef.current.onclick = () => {
                    setPopupClosed(true)
                    setOpenClass('')
                    orbitControls.current.enabled = true

                    // Workaround to fix the bug with orbit controls not updating on first hairdresser click
                    setTimeout(() => {
                        orbitControls.current.enabled = true
                    }, animationDuration * 1000 + 10) // convert seconds to miliseconds and add 10 to make sure that the orbit controls are enabled
                    // Workaround to fix the bug with orbit controls not updating on first hairdresser click
                    
                    animateCamera(
                        cameraCoordinates.x,
                        cameraCoordinates.y,
                        cameraCoordinates.z,
                        0, 0, 0)
                    // wait for the animation to finish
                    setTimeout(() => {
                        setPricelistLoaded(false)
                    },animationDuration * 1000) // convert seconds to miliseconds
                }
            }
            if (priceList) {
                gsap.from(priceList.current, {duration: .3, x: -100, opacity: 0, delay: animationDuration, ease: "sine.inOut"})
            }
        }, 500)

        // Add 'open' class after 1 second
        const timer = setTimeout(() => {
            setOpenClass('open')
        }, animationDuration * 1000)

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer)
    }, [])

    return (
        <Html
            as='div'
            wrapperClass={`popup-parent price-list-wrapper ${openClass}`}
        >
            <span ref={closeButtonRef} className='popup-parent__close-button'>X</span>
            <div ref={priceList} className='price-list-wrapper__container'>
                <div className='price-list'>
                    {content.map((item, index) => {
                        return (
                            <div className='price-list__item' key={index}>
                                <span className='price-list__service'>{item.service}</span>
                                <span className='price-list__dots'/>
                                <span className="price-list__price">{item.price}</span>
                            </div>
                        )
                    })}
                </div>
                <div className='price-list-wrapper__working-hours' >
                    <p>Радно време:</p>
                    <p>Пон-пет 13-20</p>
                    <p>Суб 9-15</p>
                </div>
            </div>
        </Html>
    )
}