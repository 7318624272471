import { Html } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import useCameraCoordinates from '../stores/useCameraCoordinates'

export default function Hairdresser({ 
    content, 
    setLoadedHairdresser, 
    animateCamera, 
    animationDuration, 
    orbitControls, 
    setPopupClosed
 }) {

    const cameraCoordinates = useCameraCoordinates(state => state.cameraCoordinates)

    /**
     * Close the hairdresser pop-up
     */
    const closeButtonRef = useRef()
    const image = useRef()
    const [openClass, setOpenClass] = useState('')

    useEffect(() => {
        // Close button handler
        setTimeout(() => {
            if (closeButtonRef) {
                closeButtonRef.current.onclick = () => {
                    setPopupClosed(true)
                    setOpenClass('')
                    orbitControls.current.enabled = true

                    // Workaround to fix the bug with orbit controls not updating on first hairdresser click
                    setTimeout(() => {
                        orbitControls.current.enabled = true
                    }, animationDuration * 1000 + 100) // convert seconds to miliseconds and add 10 to make sure that the orbit controls are enabled
                    // Workaround to fix the bug with orbit controls not updating on first hairdresser click
                    
                    animateCamera(
                        cameraCoordinates.x,
                        cameraCoordinates.y,
                        cameraCoordinates.z,
                        0, 0, 0)
                    // wait for the animation to finish
                    setTimeout(() => {
                        setLoadedHairdresser('')
                    },animationDuration * 1000) // convert seconds to miliseconds
                }
            }
            // Fade in the image and the text
            if (image) {
                gsap.from(image.current, {duration: .3, x: -100, opacity: 0, delay: animationDuration, ease: "sine.inOut"})
            }
        }, 500)

        // Add 'open' class after 1 second
        const timer = setTimeout(() => {
            setOpenClass('open')
        }, animationDuration * 1000)

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer)
    }, [])

    return (
        <Html
            as='div'
            wrapperClass={`popup-parent ${openClass}`}
        >
            <span ref={closeButtonRef} className='popup-parent__close-button'>X</span>
            <div className='popup-parent__content'>
                <div className='popup-parent__image' ref={image}>
                    <img src={`${content.imageURL}`} alt={`${content.title} popup-parent`}/>
                </div>
                <div className='popup-parent__text'>
                    <h2>{content.title}</h2>
                    <p>{content.text}</p>
                    <p className='popup-parent__phone' >
                        <img src='./svgs/phone-ringing-505.svg' alt='phone-icon' />
                        <a href={`tel:${content.phone}`}>{content.phone}</a>
                    </p>
                </div>
            </div>
        </Html>
    )
}