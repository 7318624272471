import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
// import { Perf } from 'r3f-perf'
import Saloon from './Saloon'
import Light from './Lights'
import { useRef } from 'react'
import CameraPosition from './CameraPosition'

export default function Experience() {

    const orbitControls = useRef()

    return (
        <>
            <Canvas
                flat
                dpr={[1,2]}
            >
                <color args={['#241a1a']} attach='background' />
                {/* Performance monitoring */}
                {/* <Perf position='top-left' /> */}
                <OrbitControls 
                    ref={orbitControls} 
                    makeDefault
                    maxPolarAngle={Math.PI / 2 + 0.17}
                />
                <Light/>
                <Saloon orbitControls={orbitControls} />
                {/* Position the camera according to the screen width */}
                <CameraPosition/>
            </Canvas>
        </>
    )
}