import { create } from 'zustand'

export default create((set) => {
    return {
        cameraCoordinates: {},
        setCameraCoordinates: (cameraCoordinates) => {
            set(() => {
                return { cameraCoordinates }
            })
        }
    }
})