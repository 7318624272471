import { useThree } from "@react-three/fiber"
import useCameraCoordinates from "./stores/useCameraCoordinates"
import { useEffect } from "react"

export default function CameraPosition() {

    const { camera } = useThree()
    const setCameraCoordinates = useCameraCoordinates(state => state.setCameraCoordinates)

    useEffect(() => {
    /**
     * Position the camera according to the screen width
     */
        let cameraCoordinates = null

        const positionTheCamera = () => {
            const viewportWidth = window.innerWidth
            const setTheCameraPosition = () => { // Helper function to reduce the camera.position.set repetition
                camera.position.set(
                    cameraCoordinates.x,
                    cameraCoordinates.y,
                    cameraCoordinates.z
                )
            }
    
            if (viewportWidth < 1000 && viewportWidth > 800) {
                cameraCoordinates = {
                    x: 10.88,
                    y: 2.72,
                    z: 13.60
                }
                setTheCameraPosition()
            }
            else if (viewportWidth <= 800 && viewportWidth > 550) {
                cameraCoordinates = {
                    x: 13.36,
                    y: 3.34,
                    z: 16.7
                }
                setTheCameraPosition()
            }
            else if (viewportWidth <= 550) {
                cameraCoordinates = {
                    x: 15.58,
                    y: 3.89,
                    z: 19.47
                }
                setTheCameraPosition()
            }
            else { // Screens above 1000px viewport width
                cameraCoordinates = {
                    x: 8,
                    y: 2,
                    z: 10
                }
                setTheCameraPosition()
            }
        }
        positionTheCamera() // position the camera initially
        setCameraCoordinates(cameraCoordinates)
    }, [])

    return null
}